import React from 'react'
import Icon from '@/component/Primitive/Icon'
import ageRatingShape from '@/shape/age-rating'
import { useRatingContext } from '@/component/Context/RatingContext'

export const sizes = [33, 39, 50, 55, 63, 67, 71, 90, 99, 108, 131]

const AgeRating = ({ size, className, rating }) => {
  const contextRating = useRatingContext()
  const hasRating = rating || contextRating
  const derivedRating = hasRating && (rating || contextRating)
  const lowerCaseDerivedRating =
    (derivedRating && derivedRating.toLowerCase()) || ''
  return (
    <Icon
      className={className}
      width={sizes[size]}
      type={`rating-${lowerCaseDerivedRating}`}
      a11yText={`Rated ${derivedRating}`}
    />
  )
}

AgeRating.defaultProps = {
  size: 5
}

AgeRating.propTypes = {
  ...ageRatingShape
}

export default AgeRating
