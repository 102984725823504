import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import Element from '../Element'

import styles from './Container.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

export const sizes = ['small', 'medium', 'large', 'wide', 'huge', 'full']

const Container = ({
  as,
  center,
  children,
  className,
  gutter,
  noClearfix,
  size,
  ...other
}) => {
  const isDark = useDarkContext()
  return (
    <Element
      as={as}
      className={classNames(
        styles.Container,
        center && styles.center,
        gutter && styles.gutter,
        noClearfix && styles.noClearfix,
        size && styles[size],
        isDark && styles.dark,
        className
      )}
      {...other}
    >
      {children}
    </Element>
  )
}
Container.propTypes = {
  as: string,
  center: bool,
  children: node.isRequired,
  className: string,
  gutter: bool,
  noClearfix: bool,
  size: oneOf(sizes)
}

export default Container
