import React from 'react'
import { node, oneOf } from 'prop-types'
import { displayRatings } from '../../../../../data/ratings.json'

const RatingContext = React.createContext()

const RatingContextProvider = ({ children, rating }) => (
  <RatingContext.Provider value={rating}>{children}</RatingContext.Provider>
)

RatingContextProvider.propTypes = {
  children: node.isRequired,
  rating: oneOf(displayRatings)
}

const RatingContextConsumer = RatingContext.Consumer
const useRatingContext = () => React.useContext(RatingContext)

export {
  RatingContext,
  RatingContextProvider,
  RatingContextConsumer,
  useRatingContext
}
