import React from 'react'
import { number, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import svgDimensionsFormatter from '../../../lib/svg-dimensions-formatter'
import ratioScaler from '../../../lib/ratio-scaler'

import styles from './Icon.module.scss'

const svgs = require.context('../../../asset/svg/icon/', false, /\.svg$/)

const defaultRatingSvg = (rating) => {
  return () => (
    <svg viewBox="0 0 90 80">
      <circle cx="45" cy="40" r="40" fill="black" />
      <text
        x="50%"
        y="50%"
        fontFamily="Arial, Helvetica, sans-serif"
        fontSize="20px"
        fill="#FFF"
        textAnchor="middle"
        dy=".3em"
      >
        {rating}
      </text>
    </svg>
  )
}

// Exported to allow iteration in storybook
export const vAligns = ['baseline', 'bottom', 'middle', 'top']
export const types = svgs
  .keys()
  .map((key) => key.replace(`./`, '').replace(`.svg`, ''))
  .concat([
    'rating-',
    'rating-a',
    'rating-aa',
    'rating-x',
    'rating-uc',
    'rating-null'
  ])

const Icon = ({ a11yText, className, type, height, width, vAlign }) => {
  let SvgType = null
  try {
    const typeToFind = type.includes('rating-') ? type.replace('/', '') : type
    SvgType = svgs(`./${typeToFind}.svg`).default
  } catch (e) {
    if (type.includes('rating')) {
      SvgType = defaultRatingSvg(type.replace('rating-', '').toUpperCase())
    } else {
      throw e
    }
  }

  const targetDimensions = { width, height }
  const nativeDimensions = svgDimensionsFormatter(SvgType)
  const ratioDimensions = ratioScaler(
    targetDimensions,
    nativeDimensions,
    'ceil'
  )

  return (
    <span
      className={classNames(styles.Icon, vAlign && styles[vAlign], className)}
      {...(a11yText && {
        role: 'img',
        'aria-label': a11yText
      })}
      {...(!a11yText && {
        'aria-hidden': 'true'
      })}
      style={{
        width: `${ratioDimensions.width}px`,
        height: `${ratioDimensions.height}px`
      }}
    >
      <SvgType />
    </span>
  )
}

Icon.propTypes = {
  a11yText: string.isRequired,
  className: string,
  type: oneOf(types).isRequired,
  height: number,
  width: number,
  vAlign: oneOf(vAligns)
}

export default Icon
